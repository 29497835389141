import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import deepParseJson from 'utils/deepParseJson';
import { PERSIST_STORE_NAME } from 'constants/app.constant';

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {
	
	const roleMatched = useMemo(() => {
// 		const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
// const persistData = deepParseJson(rawPersistData);
// const assignedForms = persistData.auth.user.assignedForms;

		return authority?.some(role => userAuthority.includes(role))
	}, [authority, userAuthority])
    
    if (isEmpty(authority) || isEmpty(userAuthority) || typeof authority === 'undefined') {
		return !emptyCheck
	}else{

		return roleMatched
	}

   
}

export default useAuthority