import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setToken,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import api from "views/api/api";
import useCryptograpy from "./useCryptograpy";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { encrypt, decrypt } = useCryptograpy();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async ({ email, password }) => {
    try {
      const formData = {
        email: `${email}`,
        password: `${password}`,
      };

      const encryptedData = await encrypt(formData);

      const resp = await api.post("/admin/admin-login", { encryptedData });
      const respo = await resp.data;
      const decryptedData = await decrypt(respo.data);

      if (decryptedData && decryptedData.token) {
        const token = decryptedData.token;

        dispatch(setToken(token));

        dispatch(onSignInSuccess(token));

        if (decryptedData.record) {
          const userData = decryptedData.record;
          dispatch(
            setUser({
              id: userData.id ? userData.id : "id",
              avatar: userData.avatar ? userData.avatar : "",
              name: userData.name ? userData.name : "",
              authority: userData.role ? userData.role : "guest",
              email: userData.email ? userData.email : "test@gmail.com",
              assignedForms: userData.assignedForms
                ? userData.assignedForms
                : "",
              permissions: userData.permissions ? userData.permissions : [],
            })
          );
          window.location.reload();
        }

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: "login done",
          data: respo.data,
        };
      } else {
        return {
          status: "failed",
          message: respo?.message,
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const profileSignIn = async ({ profileIdOraadharNo, password, aadharNo }) => {
    try {
      const formData = {
        profileIdOraadharNo: profileIdOraadharNo,
        password: password,
      };

      const encryptedData = await encrypt(formData);

      const resp = await api.post("/profile-login", { encryptedData });
      const respo = await resp.data;

      const decryptedData = await decrypt(respo.data);

      if (decryptedData && decryptedData.token) {
        const token = decryptedData.token;

        dispatch(setToken(token));

        dispatch(onSignInSuccess(token));

        if (decryptedData.record) {
          const userData = decryptedData.record;
          dispatch(
            setUser({
              id: userData.id ? userData.id : "id",
              avatar: userData.avatar ? userData.avatar : "",
              name: userData.name ? userData.name : "",
              authority: userData.role ? userData.role : "guest",
              email: userData.email ? userData.email : "test@gmail.com",
              assignedForms: userData.assignedForms
                ? userData.assignedForms
                : "",
              permissions: userData.permissions ? userData.permissions : [],
            })
          );
          window.location.reload();
        }

        return {
          status: "success",
          message: "login done",
          data: respo.data,
        };
      } else {
        return {
          status: "failed",
          message: "Failed to log in, Please Check Your Credentials",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    window.location.reload();
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    // window.location.reload();
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    profileSignIn,
    signIn,
    signOut,
  };
}

export default useAuth;
