import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context for managing location data
const LocationContext = createContext();

// Custom hook for accessing location data
export const useLocationCoordinates = () => {
  return useContext(LocationContext);
};

// Provider component to manage location state and permissions
export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <LocationContext.Provider value={{ location, error }}>
      {children}
    </LocationContext.Provider>
  );
};
