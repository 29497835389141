import React, { forwardRef, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = forwardRef(({ value, onChange, ...rest }, ref) => {
  // useCallback to ensure the onChange function is not re-created on each render
  const handleChange = useCallback(
    (content) => {
      onChange(content);
    },
    [onChange]
  );
  return (
    <div className="rich-text-editor">
      <ReactQuill ref={ref} value={value} onChange={handleChange} {...rest} />
    </div>
  );
});

export default RichTextEditor;
